import React, { useEffect } from 'react';

export default function GoogleTranslate() {
  useEffect(() => {
    const googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
            {
              pageLanguage: 'en',
              includedLanguages: 'es,fr,ru,hi,pt,de,en',  // Spanish, French, Russian, Hindi, Portuguese
            },
            'google_translate_element'
          );
      }
    };

    const addGoogleTranslateScript = () => {
      if (
        !document.querySelector(
          'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
        )
      ) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src =
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        document.body.appendChild(script);
      } else {
        googleTranslateElementInit();
      }
    };

    const removeUnwantedElements = () => {
      const style = document.createElement('style');
      style.innerHTML = `
        .goog-logo-link, 
        .goog-te-gadget > span, 
        .goog-te-banner-frame, 
        .goog-te-menu-frame.skiptranslate, 
        .goog-te-banner-frame.skiptranslate {
          display: none !important;
        }
        body {
          top: 0px !important; 
        }
        body > .skiptranslate {
          display: none;
        }
        * {
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none;    /* Firefox */
          -ms-user-select: none;     /* Internet Explorer/Edge */
          user-select: none;         /* Standard syntax */
        }
        .goog-te-gadget-icon {
          display: none !important;
        }
        .goog-te-gadget {
          font-size: 0px;
        }
        .goog-te-combo option {
          color: black; /* Default color for all options */
        }
        .goog-te-combo option[value=""] {
          color: white; /* Color for the placeholder option */
          font-weight: bold; /* Bold for the placeholder option */
        }
        #goog-gt-tt {
          display:none!important;
        }
        .VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
          background-color: transparent;
          box-shadow: none;
          position: static;
        }
      `;
      document.head.appendChild(style);
    };

    const waitForTranslateElement = () => {
      const interval = setInterval(() => {
        const translateElement = document.querySelector('.goog-te-combo');
        if (translateElement) {
          clearInterval(interval);
          removeUnwantedElements();
          applyCustomStyles(); // Move custom styles application here
        }
      }, 500);
    };

    const applyCustomStyles = () => {
      const intervalId = setInterval(() => {
        const translateCombo = document.querySelector(
          '.goog-te-gadget .goog-te-combo'
        );
        if (translateCombo) {
          // Add custom styles to the dropdown
          translateCombo.style.border = '0px';
          translateCombo.style.backgroundColor = 'transparent';
          translateCombo.style.borderRadius = '7px';
          translateCombo.style.outline = 'none';
          translateCombo.style.margin = '0px';
          translateCombo.style.color = 'white';
          translateCombo.style.fontSize = '16px';
          translateCombo.style.fontWeight = '400';

          const placeholderOption = document.createElement('option');
          placeholderOption.text = 'English';
          placeholderOption.disabled = true;
          placeholderOption.selected = true;
          translateCombo.insertBefore(placeholderOption, translateCombo.firstChild);

          // Hide the default option for English if necessary
          const defaultOption = translateCombo.querySelector('option[value=""]');
          if (defaultOption) {
            defaultOption.style.display = 'none';
          }

          clearInterval(intervalId);
        }
      }, 500);
    };

    if (!window.google || !window.google.translate) {
      window.googleTranslateElementInit = googleTranslateElementInit;
      addGoogleTranslateScript();
    } else {
      googleTranslateElementInit();
    }

    window.googleTranslateElementInit = googleTranslateElementInit;
    addGoogleTranslateScript();
    waitForTranslateElement();
  }, []);

  return (
    <div>
      <div id="google_translate_element"></div>
    </div>
  );
}
