import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import toast, { Toaster } from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";


export default function Skilleareumai() {

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const user = useSelector((state) => state.user);
  const email = user ? user[0].email : '';
  const CusID = user ? user[0].CusID : '';

  const handleClick = () => {
    if (!CusID) {
      console.error("CusID not available");
      toast.error('Please Login to use MBC Exclusive');
      return;
    }
    try {
      window.open(`https://skilleareum.ai/redirect?CusID=${CusID}`, "_blank");
    } catch (error) {
      console.error("Error handling click event:", error);
    }
  };


  const [videoplay, setvideoplay] = useState(false);
  const [videoplay1, setvideoplay1] = useState(false);
  const [videoplay2, setvideoplay2] = useState(false);

  const handleVideoplay = () => {
    setvideoplay(!videoplay)
  }
  const handleVideoplay1 = () => {
    setvideoplay1(!videoplay1)
  }
  const handleVideoplay2 = () => {
    setvideoplay2(!videoplay2)
  }

  const [showTooltip, setShowTooltip] = useState(true);
  const closeTooltip = () => {
    setShowTooltip(false);
  };


  useEffect(() => {
    const hasVisited = sessionStorage.getItem('SkilleareumVideo1');
    if (!hasVisited) {
      setShowTooltip(true);
      sessionStorage.setItem('SkilleareumVideo1', 'true');
    }
  }, []);

  const navigate = useNavigate();

  const handletravel = async () => {

    try {
      if (address === undefined) {
        toast.error('Please connect the Wallet');
        return;
      }

      if (!CusID) {
        console.error("CusID not available");
        toast.error('Please Login to use MBC Exclusive');
        return;
      }
      const response = await axios.post(`${SERVER_URL}/api/getuserdetails`, { CusID });
      console.log(response, 'response')
      const { message, CusID: receivedCusID, planId } = response.data;

      if (message === 'Please upgrade your subscription.') {
        toast.error('Please upgrade your plan.');
      } else if (message === 'Limit exceeded') {
        toast.error('Limit exceeded. Please upgrade your subscription.');
      } else if (message === 'Navigate now.') {
        navigate(`/travelredirect?CusID=${receivedCusID}&planId=${planId}`);
      }
    } catch (error) {
      console.log(error, 'error')
      if (error.response) {
        console.error('Error fetching user details:', error.response.data);
      }
      toast.error('No Subscription Found for your Wallet..Please Upgrade your Plan !!! .');
    }
  };

  const handletravelmod = async () => {

    try {
      if (address === undefined) {
        toast.error('Please connect the Wallet');
        return;
      }

      if (!CusID) {
        console.error("CusID not available");
        toast.error('Please Login to use MBC Exclusive');
        return;
      }
      const response = await axios.post(`${SERVER_URL}/api/getuserdetailsmod`, { CusID });
      const { message, CusID: receivedCusID, planId } = response.data;
      if (message === 'Please upgrade your subscription.') {
        toast.error('Please upgrade your plan.');
      } else if (message === 'Limit exceeded') {
        toast.error('Limit exceeded. Please upgrade your subscription.');
      }
      else if (message === 'Navigate now.') {
        navigate(`/travelredirectmod?CusID=${receivedCusID}&planId=${planId}`);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);

      if (error.response) {
        console.error('Error fetching user details:', error.response.data);
      }
      toast.error('No Subscription Found for your Wallet..Please Upgrade your Plan !!! .');
    }
  };

  return (
    <div>
      {/* <Toaster position='top-center' /> */}

      <div className='relative mt-5 flex flex-col pb-10 space-y-5'>
        <div className=' h-full flex items-center justify-center'>

          <div className=' w-[100%] mx-auto flex flex-col md:flex-row justify-around items-center gap-5 md:gap-0' >
            <div className='md:w-[30%]'>
              <img src='/assets/SKILL 30 SEPT.jpg' className='mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
            </div>
            <div className='md:w-[60%] grid content-center'>
              <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                data-aos-duration="1000">
                <div className='flex items-center gap-4'>
                  <p className='font-bold  text-3xl uppercase cursor-pointer hover:underline' data-aos="fade-right" data-aos-duration="1000" onClick={handleClick} >Skilleareum.ai</p>
                  <FontAwesomeIcon className='cursor-pointer w-10 h-10 ' onClick={handleVideoplay} icon={faCirclePlay} />
                </div>
                {showTooltip && (
                  <div className="relative md:mt-0 mt-3" data-aos="fade-right" data-aos-duration="1000">
                    <div className="absolute left-3 top-9 transform -translate-y-1/2">
                      <div
                        style={{ clipPath: 'polygon(100% 50%, 0% 0%, 0% 100%)' }}
                        className="bg-white/20 w-5 h-5 rotate-180"
                      ></div>
                    </div>

                    <div className="relative md:pl-8 md:-top-0 bottom-0 -pl-10">
                      <div className=" z-50 p-4  bg-white/20 backdrop-blur-lg text-white rounded-md shadow-lg">
                        <button className="text-white text-lg font-bold rotate-45 absolute top-0 right-2" onClick={closeTooltip}>
                          +
                        </button>
                        <p className="text-xs 2xl:text-sm text-center font-semibold">
                        “Learn how to connect to Skilleareum” - check out this video
                        </p>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
              {videoplay && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[60%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/skilleareum MBC.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              <ul className='mt-3'>
                <li>1. The World’s First Learn & Earn Social-EduFi DAO Platform.</li>
                <li>2. Token Incentives: Earn tokens by completing educational courses.</li>
                <li>3. Global Learning Ecosystem: Participate in a decentralized, unbiased learning environment.  </li>
                <li>4. Instantaneous Rewards: Tokenize every learning achievement.</li>
              </ul>

              <div className='mt-5 font-bold' data-aos="fade-right" data-aos-duration="1250">
                How to download Telegram?
              </div>

              <div className="button-container mt-3 flex justify-start gap-4" data-aos="fade-right" data-aos-duration="1250">
                <button
                  className="toggle-button border-2 border-white text-white py-2 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay2}>
                  Windows
                </button>
                <button
                  className="toggle-button border-2 border-white text-white py-2 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay1}>
                  Mac OS
                </button>
              </div>
              {videoplay1 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay1} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/Mac tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              {videoplay2 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay2} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/windows tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=' h-full flex items-center justify-center'>
          <div className=' w-[100%] mx-auto flex flex-col md:flex-row items-center justify-around gap-5 md:gap-0' >
            <div className='md:w-[30%]'>
              <img src='/assets/travel-website-purple-ui.png' className='w-[90%] mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
            </div>
            <div className='md:w-[60%] grid content-center'>
              <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                data-aos-duration="1000">
                <div className='flex items-center gap-4'>
                  <p className='font-bold  text-3xl uppercase cursor-pointer hover:underline' data-aos="fade-right" data-aos-duration="1000" onClick={handletravel} >Gold travel savings</p>
                </div>
              </div>

              <ul className='mt-3'>
                <li>1. Hotels: Save up to 70% on 800,000 hotels worldwide.</li>
                <li>2. Car Rentals: Convenient and affordable options across the globe.</li>
                <li>3. Flights: Enjoy a seamless booking experience with our advanced flight system.</li>
                <li>4. Parks and Tickets: Discover exciting attractions and book your tickets with ease.</li>
                <li>5. Things to Do: Find and book activities with discounts up to 50% off.</li>
                <li>6. Online Deals: Avail exclusive deals globally.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className=' h-full flex items-center justify-center'>
          <div className=' w-[100%] mx-auto flex flex-col md:flex-row justify-around gap-5 md:gap-0' >
            <div className='md:w-[30%]'>
              <img src='/assets/tourism-website-ui.png' className='w-[90%] mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
            </div>
            <div className='md:w-[60%] grid content-center'>
              <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                data-aos-duration="1000">
                <div className='flex items-center gap-4'>
                  <p className='font-bold  text-3xl uppercase cursor-pointer hover:underline' data-aos="fade-right" data-aos-duration="1000" onClick={handletravelmod} >Platinum travel savings</p>
                </div>
              </div>

              <ul className="mt-3">
                <li>1. Hotels: Access 1,000,000 hotels with discounts up to 70%.</li>
                <li>2. Flights: Experience our new flight system for the best deals.</li>
                <li>3. Activities: Book activities worldwide with savings up to 50%.</li>
                <li>4. Cars, Transfers, and Trains: Find reliable transportation options globally.</li>
                <li>5. Vacation Rentals and Resort Weeks: Enjoy popular vacation spots with up to 70% off.</li>
                <li>6. Cruises: Earn 10% cashback in Trip Coins plus onboard credits, redeemable for future purchases excluding flights.</li>
                <li>7. Tours: Discover global tours with discounts up to 50%.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}