import React, { useState, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Support = () => {
    const user = useSelector((state) => state.user);
    const email = user[0]?.email || "";
    const CusID = user[0]?.CusID || "";

    const [formData, setFormData] = useState({ subject: '', description: '', });
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024;

        if (file && file.size > maxSize) {
            toast.error("File size exceeds 10 MB. Please select a smaller file.");
            return;
        }
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { subject, description } = formData;

        const Data = new FormData();
        Data.append('CusID', CusID);
        Data.append('email', email);
        Data.append('subject', subject);
        Data.append('description', description);
        if (image) {
            Data.append('image', image);
        }

        try {
            const response = await fetch(`${SERVER_URL}/api/AddTickets`, {
                method: 'POST',
                body: Data,
            });

            if (response.ok) {
                toast.success('Ticket Submitted Successfully');
                setLoading(false);
                setFormData({ subject: '', description: '' });
                setImage(null);
            } else {
                toast.error('Failed to submit ticket');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting ticket:', error);
            toast.error('Error submitting ticket');
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="w-[80%] mx-auto font-poppins">
                <h2 className="text-xl font-bold text-white mt-8">Raise Ticket</h2>
                <form onSubmit={handleSubmit} className="mt-6 bg-white/10 p-6 rounded-lg shadow-lg">
                    <div className="space-y-4">
                        <input
                            type="email"
                            name="mailId"
                            value={email}
                            disabled
                            className="w-full p-3 bg-transparent border border-white/50 rounded-lg outline-none cursor-not-allowed"
                        />
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            placeholder="Enter Subject"
                            required
                            className="w-full p-3 text-white bg-transparent border border-white/50 rounded-lg outline-none placeholder:text-white"
                        />
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Describe your issue..."
                            required
                            className="w-full p-3 text-white bg-transparent border border-white/50 rounded-lg outline-none placeholder:text-white"
                        />
                        <div>
                            <label className="block text-gray-300 mb-1">Add Screenshot (Optional)</label>
                            <input
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                                accept="image/*"
                                className="text-sm text-gray-300"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-gradient-to-r from-[#F23985] to-[#FB9236] py-2 rounded-lg font-medium text-white shadow-md hover:opacity-90"
                        >
                            {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                            ) : (
                                "Submit Ticket"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default Support;
