import { useState } from "react";
import Header from "../../Layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import './useremail.css';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../../config";
export default function OTPVerification() {
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [SuccessEmail, setSuccessEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const CurrentCusID = useLocation().state.CusID;
    


    const handleChange = (index, value) => {
        if (value.length > 1) return;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 3) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    const handleVerify = async () => {
        const otpCode = otp.join('');
        try {
            console.log("OTP", otpCode, CurrentCusID);
            const response = await axios.post(`${SERVER_URL}/api/verify-and-update-email`, { otp: otpCode, CusID: CurrentCusID });
            if (response.data.status) {
                setSuccessEmail(true);
                setTimeout(() => {
                    navigate('/login');
                }, 15000);
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setErrorMessage('Error verifying OTP. Please try again.');
        }
    };

    return (
        <div>
            <div className='fixed inset-0 z-0 bg-gradient-to-r from-[#E43875] to-[#F98736]'>
                <img src='/assets/NewMBCbg.png' className='hidden md:block h-full w-full object-cover object-top'></img>
            </div>
            <div className='flex flex-col h-screen relative' style={{ zIndex: 1 }}>
                <div>
                    <Header />
                </div>
                <div className='flex flex-1 items-center justify-center'>
                    <div data-aos='fade-up' data-aos-duration='1000' className="flex flex-col items-center bg-white p-6 2xl:p-8 rounded-lg shadow-lg w-[90%] max-w-sm 2xl:max-w-md">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] p-3 rounded-lg">
                            <img src="/assets/email.svg" alt="email icon" className="w-6 h-6" />
                        </div>
                        <h2 className="text-xl 2xl:text-2xl font-semibold mt-4">Update Email Address</h2>
                        <p className="text-[#959595] text-xs 2xl:text-sm text-center font-semibold mt-1">
                            Enter OTP sent to your email to verify your email address.
                        </p>

                        <div className="flex gap-3 mt-4">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    id={`otp-${index}`}
                                    type="text"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    className="w-12 2xl:w-14 h-12 2xl:h-14 text-xl font-semibold outline-none text-center border rounded-md bg-gray-100 focus:ring-2 focus:ring-pink-400"
                                    maxLength="1"
                                />
                            ))}
                        </div>

                        {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}

                        <button className="mt-5 w-full bg-gradient-to-r from-[#E43875] to-[#F98736] text-white py-2 2xl:py-3 rounded-lg font-semibold" onClick={handleVerify}>
                            Verify
                        </button>

                        <p className="text-[#959595] font-semibold text-center text-sm 2xl:text-base mt-3">
                            Are you facing any problems with receiving the code?{" "}
                            <span className="bg-gradient-to-r from-[#E43875] to-[#F98736] text-transparent bg-clip-text font-semibold cursor-pointer border-b border-[#E43875]">
                                Resend Code
                            </span>
                        </p>
                    </div>
                    {SuccessEmail &&
                        <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-md">
                            <div data-aos='fade-in' data-aos-duration='1000' className="flex flex-col text-white w-[80%] min-h-[60vh] bg-gradient-to-r from-[#E43875] to-[#F98736]  border rounded-xl p-3">
                                <div className="flex justify-end text-white"><FontAwesomeIcon icon={faXmarkCircle} className="cursor-pointer" onClick={() => setSuccessEmail(false)} /></div>
                                <div className="flex flex-1 items-center justify-center">
                                    <div className="space-y-5">
                                        <div className="w-12 2xl:w-16 h-12 2xl:h-16 mx-auto rounded-full relative overflow-hidden">
                                            <img src="https://i.gifer.com/7efs.gif" className="check-gif"></img>
                                        </div>
                                        <h1 className="font-bold text-center text-2xl lg:text-4xl">Your Email Address Updated Successfully!</h1>
                                        <p className="text-center">Please log in again to ensure everything is set up and ready for you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
