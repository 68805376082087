import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { MBCPayOut_ContractAddress, SERVER_URL } from '../../config';
import { MBCPayOut_abi } from '../../ABI';
import toast from "react-hot-toast";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const PoolRewards = () => {
    const [tiers, setTiers] = useState(1);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const handleChangePrice = async () => {
        try {
            setLoading(true);
            if(!address || !isConnected){
                toast.error('Please Connect your Wallet');
                return false
            }
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
            const balance = await contract.GlobalPoolReward();
            const PoolBalance = parseInt(balance)/10 ** 18;
            console.log(PoolBalance)
            const gasPrice = await provider.getGasPrice();
            const gasLimit = await contract.estimateGas.distrubutePoolRewards();
            const tx = await contract.distrubutePoolRewards({
                gasPrice,
                gasLimit,
            });
            const receipt = await tx.wait();
            console.log(receipt)
            if (receipt.status === 1 && receipt.transactionHash) {
                toast.success("Funds Released Successfully");
            } else {
                toast.error("Transaction failed");
            }
        } catch (err) {
            console.error("Error Releasing rewards:", err);
            toast.error("Error Releasing Rewards");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='flex justify-center items-center h-full mt-24 font-poppins'>
            <div className="w-[95%] mx-auto max-w-xl relative">
                <div className="bg-white rounded-lg px-5 py-10 ">
                    <div className="lg:w-[60%] mx-auto">
                        <p className="text-black font-semibold leading-6 text-center pb-5">Release Global Pool Rewards</p>
                    </div>
                    <div className="md:w-[85%] mx-auto mt-10 md:mt-0 px-5">
                      

                        <div className="mt-5">
                            <button
                                type="submit"
                                onClick={handleChangePrice}
                                className="w-full rounded-2xl py-3 shadow-lg shadow-black/30 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-semibold"
                            >
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                ) : (
                                    "Release Rewards"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PoolRewards;
