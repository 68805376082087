import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faChevronRight, faCircleExclamation, faPlaneCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { MORALIS_API_KEY, SERVER_URL, TOKEN_ADDRESS, MBCPayOut_ContractAddress, MBCToken_ContractAddress, PROVIDER_URL } from '../../config';
import { ethers, Wallet } from 'ethers';
import BigNumber from "bignumber.js";
import { MBCPayOut_abi, token_abi} from '../../ABI';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";

export default function Analysis() {

    const user = useSelector((state) => state.user);
    const name = user[0].name;
    const email = user[0].email;
    const CusID = user[0].CusID;

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [IncomeAddress, setIncomeAddress] = useState(null);
    const [userAddress, setUserAddress] = useState(null);
    const [Rewards, setRewards] = useState(0);
    const [Team, setTeam] = useState(0);
    const [Subscription, setSubscription] = useState(0);
    const [Plan, setPlan] = useState(0);
    const [PlanType, setPlanType] = useState('')
    const [SubscribeHistory, setSubscribeHistory] = useState([]);
    const [balance, setBalance] = useState(0);


    const fetchAddress = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/fetchAddress?CusID=${CusID}`);
            if (response.data.status === true) {
                setIncomeAddress(response.data.address.income_wallet);
            }
        } catch (error) {
            console.error("Error while fetching address : ", error);
        }
    }

    const [TeamCount, setTeamCount] = useState(0);

    const fetchTeamUsersCount = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/fetchTeamUsersCount?id=${CusID}`);
            if (response.data.status === true) {
                setTeamCount(response.data.TeamCount);
            }
        } catch (error) {
            console.error("Error while fetching address : ", error);
        }
    }

    const getUserDetails = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
            const getDetails = await contract.getuserDetails(IncomeAddress);
            setUserAddress(getDetails[0]);
            setRewards(new BigNumber(getDetails[10] / 10 ** 18).toFixed());
            setSubscription(new BigNumber(getDetails[6] / 10 ** 18).toFixed());
            setTeam(parseInt(getDetails[8]));
            setPlan(parseInt(getDetails[2]))
        } catch (err) {
            console.error("Error while fetching data from contract: ", err);
        }
    }

    const plans = [
        { type: 'AFFILIATE', no: 1, amount: 7, img: '/assets/firstPlan.png' },
        { type: 'MEMBER', no: 2, amount: 50, img: '/assets/Starter.png' },
        { type: 'SILVER', no: 3, amount: 200, img: '/assets/StarterPackages_01.png' },
        { type: 'GOLD', no: 4, amount: 600, img: '/assets/StarterPackages_02.png' },
        { type: 'PLATINUM', no: 5, amount: 1500, img: '/assets/StarterPackages_03.png' },
        { type: 'EXECUTIVE', no: 6, amount: 3000, img: '/assets/StarterPackages_04.png' },
        { type: 'AMBASSADOR', no: 7, amount: 5000, img: '/assets/StarterPackages_05.png' },
        { type: 'PRESIDENT', no: 8, amount: 10000, img: '/assets/StarterPackages_06.png' },
    ];

    useEffect(() => {
        const selectedPlan = plans.find(plan => plan.no === Plan);

        if (selectedPlan) {
            setPlanType(selectedPlan.type);
        } else {
            setPlanType('FREE');
        }
    }, [Plan]);

    const getSubRecords = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/fetchSubsHistory?CusID=${CusID}`);
            if (response.data.status === true) {
                setSubscribeHistory(response.data.data);
            }
        } catch (error) {
            console.error("Error while fetching history: ", error);
        }
    };

    useEffect(() => {
        if (email) {
            fetchAddress();
            fetchTeamUsersCount();
        }
    }, [email]);


    useEffect(() => {
        if (IncomeAddress && address) {
            if (IncomeAddress === address) {
                getUserDetails();
                getSubRecords();
            }
        }

        if (address === undefined) {
            setRewards(0);
            setSubscription(0);
            setTeam(0);
            setSubscribeHistory([])
        }
    }, [IncomeAddress, address]);

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = SubscribeHistory.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(SubscribeHistory.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const hostName = `${window.location.protocol}//${window.location.host}`;
    const [link] = useState(`${hostName}/Signup?ref_id=${user[0].CusID}`);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
            toast('Link copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    const openImageInNewTab = (entity) => {
        window.open(`https://bscscan.com/tx/${entity}`, '_blank');
    };

    const getDisplayId = (entity) => {
        const id = entity.TrID ?? entity.trx_hash;
        return typeof id === 'string' ? id.slice(0, 7) : 'N/A';
    };

    const fetchBalance = async (walletAddress) => {
        try {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const contract = new ethers.Contract(MBCToken_ContractAddress, token_abi, provider);

            const balance = await contract.balanceOf(walletAddress);

           const formattedBalance = ethers.utils.formatUnits(balance, 18);
           setBalance(formattedBalance);
        } catch (error) {
            console.error("Error fetching balance:", error);
        }
    };

    useEffect(() => {
        if (address) {
            fetchBalance(address);
        }
    }, [address]);

    return (
        <div data-aos="fade-in" data-aos-duration='2000'>
            <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
                <div className='flex flex-col lg:flex-row gap-5 md:gap-0 justify-between items-center'>
                    <div>
                        <p className='uppercase  font-bold text-lg md:text-2xl'>Welcome back, {name} !</p>
                        <p className='mt-2'> Subscription Package :  <span className='font-bold'> {PlanType}</span></p>
                    </div>
                    {address === undefined ? (
                        <div className='text-sm 2xl:text-base flex gap-2 items-center bg-white rounded-md text-black py-1 px-2' >
                            <FontAwesomeIcon icon={faCircleExclamation} className='text-[#ff0000]' />
                            Please connect your wallet
                        </div>
                    ) : IncomeAddress !== address ? (
                        <div className='text-sm 2xl:text-base flex gap-2 items-center bg-white rounded-md text-black py-1 px-2' >
                            <FontAwesomeIcon icon={faCircleExclamation} className='text-[#ff0000]' />
                            Incorrect Wallet Address. Connect the correct wallet.
                        </div>
                    ) : Plan <= 0 ? (
                        <div className='text-sm 2xl:text-base flex gap-2 items-center bg-white rounded-md text-black py-1 px-2' >
                            <FontAwesomeIcon icon={faCircleExclamation} className='text-[#ff0000]' />
                            Subscribe to the package and receive your referral code
                        </div>
                    ) : (
                        <div className='flex items-center gap-2'>
                            <p>Referral link</p>
                            <div className='flex items-center bg-white rounded-md text-black'>
                                <p className='px-4 text-sm truncate'>
                                    {link.substring(0, 30)}...
                                </p>
                                <a
                                    className='py-2 px-3 bg-[#2794EB] text-white text-lg cursor-pointer'
                                    onClick={() => { copyToClipboard(link) }}
                                >
                                    <FontAwesomeIcon icon={faCopy} />
                                </a>
                                <a
                                    className='py-2 px-3 bg-[#4dca88] rounded-r-md text-white text-lg'
                                    href={`whatsapp://send?text=${link}`}
                                >
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <div className='mt-5 grid gap-5 grid-cols-2 lg:grid-cols-4 content-center'>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>Income</h1>
                        <p className='uppercase  font-bold text-xl my-1'>$ {Number(Rewards).toFixed(2)}</p>
                        <p className='text-xs'>Analytics for Referral Reward</p>
                    </div>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>My Team</h1>
                        <p className='uppercase  font-bold text-xl my-1'>{TeamCount}</p>
                        <p className='text-xs'>Analytics for Subscribed Team Member</p>
                    </div>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>Total Subscription</h1>
                        <p className='uppercase  font-bold text-xl my-1'>${" "}{Subscription}</p>
                        <p className='text-xs'>Analytics for Amount Deposited</p>
                    </div>
                </div>
                <div className='mt-5 flex flex-col md:flex-row gap-5'>
                    <div className='md:w-[100%]'>
                        <div className=' border border-white/50 border-dashed rounded-md pb-3'>
                            <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3'>
                                <div className='md:w-[40%]'>
                                    <p className='my-auto font-bold text-lg'>Transaction reports</p>
                                </div>
                                <div className='flex items-center'>
                                    <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                        <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                            <option className='text-black' value="5">5</option>
                                            <option className='text-black' value="10">10</option>
                                            <option className='text-black' value="100">100</option>
                                            <option className='text-black' value="200">200</option>
                                        </select>
                                    </p>
                                </div>
                            </div>
                            <div className='mb-5 border-y overflow-hidden overflow-x-auto'>
                                <table className='w-full text-center text-sm'>
                                    <thead className='uppercase border-b py-3'>
                                        <tr>
                                            <th className='py-3'>SI.No</th>
                                            <th>Cust ID</th>
                                            <th>Hash</th>
                                            <th>Date</th>
                                            <th>Package Amount</th>
                                            <th>Package</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide-y'>
                                        {currentEntities.length > 0 ? (
                                            currentEntities.map((entity, index) => (
                                                <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                                                    <td className='px-5 md:px-0 '>{(currentPage - 1) * entitiesPerPage + index + 1}</td>
                                                    <td className='px-5 md:px-0'>{entity.CustID ?? entity.CusID}</td>
                                                    <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.TrID ?? entity.trx_hash)}>
                                                        {getDisplayId(entity)}...
                                                    </td>
                                                    <td className='px-5 md:px-0'>{formatDate(entity.dot ?? entity.createdAt)}</td>
                                                    <td className='px-5 md:px-0'>${Number(entity.amount).toFixed(0)}</td>
                                                    <td className='px-5 md:px-0'>{entity.plan}</td>
                                                    <td className='px-5 md:px-0 text-green-500'>SUCCESS</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className='text-center h-[280px]'>
                                                    <p>No Data</p>
                                                </td>
                                            </tr>
                                        )}
                                        {currentEntities.length > 0 && Array.from({ length: emptyRowCount }).map((_, index) => (
                                            <tr key={`empty1-${index}`} className='h-14'>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-2 flex justify-between text-xs px-3'>
                                <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                                <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}
