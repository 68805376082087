import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Layout/Header';
import axios from 'axios';
import './useremail.css';
import { SERVER_URL } from '../../../config';
import { useLocation } from 'react-router-dom';
export default function Hello_user() {
    const [active, setActive] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const CurrentCusID = useLocation().state.CurrentCusID;
    
    const handleUpdateEmail = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${SERVER_URL}/api/generate-otp-update`, { newEmail, CusID: CurrentCusID });
            console.log("response", response.data);
            if (response.data.success) {
                setMessage('OTP sent to your new email address.');
                navigate('/Otp', { state: { newEmail, CusID: CurrentCusID } });
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            setMessage('Error sending OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='text-white'>
            <div className='fixed inset-0 z-0 bg-gradient-to-r from-[#E43875] to-[#F98736]'>
                <img src='/assets/NewMBCbg.png' className='hidden md:block h-full w-full object-cover object-top'></img>
            </div>
            <div className='flex flex-col h-screen relative' style={{ zIndex: 1 }}>
                <div>
                    <Header />
                </div>
                <div className='flex flex-1 items-center justify-center pt-7 md:pt-0'>
                    <div className='w-[90%] mx-auto space-y-7'>
                        <img data-aos='fade-down' data-aos-duration='500' src='/assets/Under.svg' className='w-10 2xl:w-14 mx-auto'></img>
                        <h1 data-aos='fade-in' data-aos-duration='1000' className='font-bold text-2xl lg:text-4xl text-center leading-12'>Hello! User <br></br> Email Already in Use</h1>
                        <p data-aos='fade-in' data-aos-duration='1500' className='text-center md:w-[75%] mx-auto'>The email ID you entered is already associated with multiple accounts. To proceed, please update the email for your existing account. You will need to verify the new email address to continue.</p>
                        {message && <p className='text-center text-white'>{message}</p>}
                        {active ? (
                            <div className='fade-in shadow-md w-[100%] md:w-[65%] mx-auto bg-white rounded-lg lg:rounded-full p-3 flex flex-wrap justify-center md:flex-row items-center gap-3'>
                                <img src='/assets/cookie.svg'></img>
                                <p className='text-sm md:text-xs text-black'>Ensure it's a valid email format and not already in use.</p>
                                <button className='shadow-black/25 rounded-full shadow-md p-[2px] text-sm bg-gradient-to-r from-[#e43785] to-[#F98736]'>
                                    <button className='px-5 py-1.5 bg-white rounded-full '>
                                        <p className='bg-clip-text text-transparent font-semibold bg-gradient-to-r from-[#e43785] to-[#F98736]' onClick={() => setActive(false)}>Edit Email</p>
                                    </button>
                                </button>
                                <button className='shadow-black/25 rounded-full font-semibold shadow-md py-1.5 text-sm bg-gradient-to-r from-[#e43785] to-[#F98736] px-5' onClick={handleUpdateEmail} disabled={loading}>
                                    {loading ? 'Sending...' : 'Send Verification code'}
                                </button>
                            </div>
                        ) : (
                            <div className='fade-in w-[100%] md:w-[50%] mx-auto bg-[#E8E8E8] rounded-lg p-3 flex flex-col gap-3 md:gap-0 md:flex-row items-center'>
                                <input
                                    className='bg-transparent font-semibold p-2 outline-none w-[100%] md:w-[70%] text-[#e43785]'
                                    type='email'
                                    placeholder='Enter your new email address'
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                />
                                <button className='shadow-black/25 rounded-full font-semibold shadow-md py-1.5 px-3 text-sm bg-gradient-to-r from-[#e43785] to-[#F98736] md:w-[30%]' onClick={() => setActive(true)}>Update Email</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
