import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL, MBCPayOut_ContractAddress } from '../../../config';
import { ethers } from 'ethers';
import BigNumber from "bignumber.js";
import { MBCPayOut_abi } from '../../../ABI';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import 'react-toastify/dist/ReactToastify.css';

export default function WithdrawIncome() {

  const user = useSelector((state) => state.user);
  const email = user[0].email;
  const CusID = user[0].CusID;

  const [IncomeAddress, setIncomeAddress] = useState(null)
  const [IncomeBalance, setIncomeBalance] = useState(0)
  const [tableEntries, settableEntries] = useState([])
  const [totalAmount, setTotalAmount] = useState(0);
  const { walletProvider, walletProviderType } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [withdrawBalance, setWithdrawBalance] = useState(0)

  const fetchAddress = async () => {
    const response = await axios.get(`${SERVER_URL}/api/fetchAddress?CusID=${CusID}`);
    if (response.data.status === true) {
      setIncomeAddress(response.data.address.income_wallet)
    }
  }

  const fetchHistory = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/fetchWithdrawHistory?CusID=${CusID}`);
      if (response.data.status === true) {
        settableEntries(response.data.data);
        const total = response.data.data.reduce((acc, entry) => acc + parseFloat(entry.amount), 0);
        setTotalAmount(total);
      }
    } catch (error) {
      console.error("error : ", error)
    }

  }

  useEffect(() => {
    if (CusID !== null) {
      fetchHistory();
      fetchAddress();
    }
  }, [CusID])

  const getUserDetails = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
      let getDetails = await contract.getuserDetails(IncomeAddress);

      console.log("raw data :", getDetails[10]); // Raw BigNumber
      console.log("exact value without dividing :", getDetails[10].toString()); // Exact value as string

      const exactValue = ethers.utils.formatUnits(getDetails[10], 18); // Divide by 1e18 but keep precision
      console.log("after dividing data :", exactValue);
      setWithdrawBalance(getDetails[10].toString())
      setIncomeBalance(exactValue);
    } catch (err) {
      console.log("Error while fetching the data from contract : ", err);
    }
  };

  useEffect(() => {
    if (IncomeAddress !== null) {
      getUserDetails()
    }
  }, [IncomeAddress])

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const withdrawRewards = async () => {
    if (!isConnected) {
      toast.error("Please Connect Wallet First");
      return false;
    }

    if (IncomeBalance <= 0) {
      toast.error("No Rewards to withdraw!");
      return false;
    }

    // if (IncomeBalance <= 1) {
    //   toast.error("Minimum withdrawal: $1");
    //   return false;
    // }

    const toastId = toast.loading("Processing withdrawal...");

    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const TokenContract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
      const withdrawAmount = ethers.BigNumber.from(
        ethers.utils.parseUnits(IncomeBalance.toString(), 18)
      ).toString();

      console.log(withdrawAmount)
      const tx = await TokenContract.withdrawRewards(address, withdrawAmount, {
        gasPrice: ethers.utils.parseUnits("5", "gwei"),
        gasLimit: 300000
      }
      );
      await tx.wait();

      console.log(tx);
      const withdraw = await axios.post(`${SERVER_URL}/api/withdrawRewards`, {
        incomeAddress: IncomeAddress,
        email: email,
        amount: IncomeBalance,
        CusID: CusID,
        receiverAddress: address,
        hash: tx.hash,
      });

      if (withdraw.data.status === true) {
        toast.dismiss(toastId);
        toast.success('Withdrawal Successful!');
        getUserDetails()
        fetchHistory();
      } else {
        toast.dismiss(toastId);
        toast.error("Withdrawal Failed!");
      }

    } catch (error) {
      console.error("Error withdrawing rewards:", error);
      toast.dismiss(toastId);
      toast.error("Withdrawal Failed!");
    }
  };

  const openImageInNewTab = (entity) => {
    window.open(`https://bscscan.com/tx/${entity}`, '_blank');
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  return (
    <div className='h-[88vh] '>
      {/* <Toaster position='top-center' /> */}
      <div className='md:pl-5 py-5 md:pr-10 font-poppins relative '>
        <div className='z-0'>
          <div className='md:w-[70%] mx-auto flex flex-col md:flex-row justify-center gap-5 '>
            <div className='border-[2px] md:w-[50%] border-white/50 rounded-md text-center px-2 py-5 shadow-md flex items-center justify-center gap-3'>
              <div><img src='/assets/wallet.png' className='mx-auto w-[60%]'></img></div>
              <div>
                <h1 className='tracking-wider uppercase text-sm'>Income Balance</h1>
                <p className='uppercase  font-bold text-xl my-1'>$ {IncomeBalance}</p>
                <p className='text-xs'>Analytics for Income Balance</p></div>
            </div>
            <div className='border-[2px] md:w-[50%] border-white/50 rounded-md text-center p-2 py-5 shadow-md flex items-center justify-center gap-3'>
              <div><img src='/assets/wallet.png' className='mx-auto w-[60%]'></img></div>
              <div><h1 className='tracking-wider uppercase text-sm'>Total Withdrawn</h1>
                <p className='uppercase  font-bold text-xl my-1'>$ {totalAmount}</p>
                <p className='text-xs'>Total Withdrawn amount</p></div>
            </div>
          </div>
          {/* 
          <div className='md:w-[70%] mx-auto px-7 py-3 rounded-md mt-10'>
            <p className='font-bold'>Important Note :</p>
            <ul className='mt-2 pl-10 list-disc'>
              <li>Ensure the connected wallet is User's registered wallet Address.</li>
            </ul>
          </div>
          */}
          {/*
          <div className='md:w-[70%] mx-auto mt-5'>
            <button className='w-full py-3 text-sm font-bold rounded-md bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30'
              onClick={withdrawRewards}>
              Withdraw Rewards</button>
          </div>
           */}
           <div className='mt-10 rounded-2xl gap-4 lg:gap-0 py-5 lg:px-10 bg-gradient-to-b from-[#E43875] to-[#F98736] flex flex-col lg:flex-row justify-center items-center'>
           <div className='lg:w-[55%]'>
              <p className='text-white uppercase font-dmsans  lg:text-lg flex items-center gap-2'><img src='/assets/Dash.svg'></img>MBC SUPPORT TEAM</p>
              <p className='text-white mt-2 lg:text-6xl text-xl font-poppins' style={{fontWeight: "900" }}>Website Under Maintenance </p>
           </div> 
           <div className='lg:w-[45%] flex justify-center'>
              <img className='w-10/12' src='/assets/MBC-Maintanence.svg'></img>
           </div>
      </div>
          <div className='rounded-2xl mt-5'>
            <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
              <div className='md:w-[40%]'>
                <p className='my-auto font-bold text-xl '>Withdraw History</p>
              </div>
              <div className='flex items-center'>
                <p className='text-end text-sm px-3 my-auto'>Show no of entity
                  <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                    <option className='text-black' value="5">5</option>
                    <option className='text-black' value="10">10</option>
                    <option className='text-black' value="100">100</option>
                    <option className='text-black' value="200">200</option>
                  </select>
                </p>
              </div>
            </div>
            <div className='mb-5 border border-dashed rounded-xl overflow-hidden overflow-x-auto'>
              <table className='w-full text-center  '>
                <thead className='uppercase font-bold text-sm border-b py-3'>
                  <tr>
                    <th className='py-3 px-2'>SI.no</th>
                    <th className='px-2'>CustID</th>
                    <th className='px-2'>Date & Time</th>
                    <th className='px-2'>Amount ($)</th>
                    <th className='px-2'>Chain</th>
                    <th className='px-2'>TxID</th>
                    <th className='px-2'>Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                      <td className='px-5 md:px-0 '>{(currentPage - 1) * entitiesPerPage + index + 1}</td>
                      <td className='px-5 md:px-0'>{entity.CusID}</td>
                      <td className='px-5 md:px-0'>{formatDate(entity.created_at)}</td>
                      <td className='px-5 md:px-0'>{Number(entity.amount).toFixed(2)}</td>
                      <td className='px-5 md:px-0'>{entity.network}</td>
                      <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.transaction_hash)}>
                        {entity.transaction_hash.slice(0, 10)}...
                      </td>
                      <td className='px-5 md:px-0'>{entity.status}</td>
                    </tr>
                  ))}
                  {Array.from({ length: emptyRowCount }).map((_, index) => (
                    <tr key={`empty-${index}`} className='h-16'>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <div className='my-auto flex justify-center items-center gap-1'>
                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
              </div>
              <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}