import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faWallet, faUser, faCube, faLifeRing, faFile, faCreditCard, faMoneyBillTransfer, faAddressCard, faArrowRightFromBracket, faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import DashboardHeader from './DashboardHeader';
import AccountSettings from './Profile/AccountSettings';
import Analysis from './Analysis';
import { Toaster } from 'react-hot-toast';
import Deposit from './Add Funds/Deposit';
import Addfunds from './Add Funds/Addfunds';
import SubscriptionHistory from './Subcription/SubscriptionHistory';
import Subscription from './Subcription/Subscription';
import AllTeam from './My Team/AllTeam';
import DirectTeam from './My Team/DirectTeam';
import InDirectTeam from './My Team/InDirectTeam';
import ActiveTeam from './My Team/ActiveTeam';
import InactiveTeam from './My Team/InactiveTeam';
import VisualStructure from './My Team/VisualStructure';
import DirectIncome from './Reports/DirectIncome';
import LevelIncome from './Reports/LevelIncome';
import Skilleareumai from './Skilleareumai';
import WithdrawIncome from './Withdraw/WithdrawIncome';
import WithdrawReport from './Withdraw/WithdrawReport';
import Whitelist from './Subcription/whitelist';
import AddWhiteList from './Subcription/AddWhitelist';
import UserTickets from './UserTickets';

import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../../store';
import { useLocation } from 'react-router-dom';
import Support from './Support';
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { MBCPayOut_abi } from '../../ABI';
import { PROVIDER_URL, MBCPayOut_ContractAddress } from '../../config';
import { ethers } from "ethers";


export default function MyDashboard() {

    const [selectedTab, setSelectedTab] = useState('Dashboard');
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [activeSubTab, setActiveSubTab] = useState(null);
    const [active, setActive] = useState('div1');
    const [Addfund, setAddfund] = useState(false);
    const [Subscribe, setSubscribe] = useState(false);
    const [Team, setTeam] = useState(false);
    const [Report, setReport] = useState(false);
    const [withdraws, setwithdraws] = useState(false);
    const [whitelist, setwhitelist] = useState(false);
    const [support, setSupport] = useState(false);

    const { address } = useWeb3ModalAccount();

    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar);
    };

    const user = useSelector((state) => state.user);
    const email = user ? user[0].email : '';

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const profile = params.get('tab');

        if (profile === 'account') {
            setActiveTab(profile);
            setActive(profile === 'account' ? 'div8' : 'div1');
        } else if (profile === 'Subscription') {
            handleTabClick('Subscription'); handleSubscribe(); handleSubTabClick('Subscribe')
        }

    }, [location]);

    const handleClickOutside = (event) => {
        if (
            (isOpenNavbar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setOpenNavbar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenNavbar]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setActiveTab(tab === activeTab ? null : tab);

        if (tab !== 'AddFund' || 'Subscription' || 'My Directs' || 'Reports' || 'Withdraw' || 'Whitelist' || 'support') {
            setAddfund(false);
            setSubscribe(false);
            setTeam(false);
            setReport(false);
            setwithdraws(false);
            setwhitelist(false);
            setSupport(false);
        }

        switch (tab) {
            case 'AddFund':
                handleAnchorClick('div2');
                break;
            case 'Subscription':
                handleAnchorClick('div3');
                break;
            case 'My Directs':
                handleAnchorClick('div4');
                break;
            case 'Reports':
                handleAnchorClick('div5');
                break;
            case 'Withdraw':
                handleAnchorClick('div6');
                break;
            case 'Products':
                handleAnchorClick('div7');
                break;
            case 'Profile':
                handleAnchorClick('div8');
                break;
            case 'Support':
                handleAnchorClick('div9');
                break;
            case 'Whitelist':
                handleAnchorClick('div10');
                break;
            default:
                handleAnchorClick('div1');
        }
    };

    const [addressStatus, setAddressStatus] = useState(false);
    const [AdminAddress, setAdminAddress] = useState('');

    const addresses = [
        '0x12b24A10cfc152Efa9b2A6FD590954fE179Bd725',
        '0x5987731299057526B84480db675b6bb549eF7D8c',
        '0x87b92f28Ffa170B377B9D2434d0973d16b91493d',
        '0x6008D005B8C77C81bd90470E1889DeC71c06494D',
        '0xD7DC24E3D4CA22e368207b1eF679b4C761E46181',
        '0x5745B1A56f126D357a54d2c682303480980AEE08',
        '0x5D0357EDB2f05AA558DAf329D34A74964cAD5668',
        '0xAe64E6466Ed28409DeD8C94a1998bD0d5E0E3687',
        '0xba1D857108d4a6adc0a3B1d593b8E1241b3e3c7c',
        // '0x5ce168Ab168Bf78b4a13C0acBFf76aBF930eBAbB',
        '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b'
    ];

    useEffect(() => {
        if (address !== undefined) {
            if (addresses.includes(address)) {
                setAddressStatus(true)
            } else {
                setAddressStatus(false)
            }
        }
        if (address !== undefined) {
            getAdminDetails()
        }
    }, [address]);

    const getAdminDetails = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
            let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
            let admin = await contract.ADMIN();
            setAdminAddress(admin)
        } catch (err) {
            console.error('Error fetching user details:', err);
        }
    };

    const dispatch = useDispatch();

    const Logout = () => {
        dispatch(setUser(""));
        sessionStorage.clear();
        // window.location = '/Login';
    };

    const handleSubTabClick = (subtab) => {
        setActiveSubTab(subtab);
    };
    const handleAnchorClick = (tab) => {
        setActive(tab); // Set main tab content visibility
    };
    const handleAddfund = () => {
        setAddfund(!Addfund); // Toggle AddFund dropdown visibility
    };
    const handleSubscribe = () => {
        setSubscribe(!Subscribe); // Toggle Subcribe dropdown visibility
    };
    const handleTeam = () => {
        setTeam(!Team); // Toggle team dropdown visibility
    };
    const handleReport = () => {
        setReport(!Report); // Toggle team dropdown visibility
    };
    const handleWithdraw = () => {
        setwithdraws(!withdraws); // Toggle team dropdown visibility
    };
    const handleWhitelist = () => {
        setwhitelist(!whitelist);
    };
    const handleSupport = () => {
        setSupport(!support);
    };
    const iconMap = {
        'cube': faCube,
        'users': faUserGroup,
        'wallet': faWallet,
        'user': faUser,
        'file': faFile,
        'credit': faCreditCard,
        'withdraw': faMoneyBillTransfer,
        'id': faAddressCard,
        'logout': faArrowRightToBracket,
        'Support': faLifeRing,

    };

    const TabButton = ({ label, tab, icon, onClick }) => (
        <button
            className={`flex justify-center gap-3 w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[75%] mx-auto captialize font-bold rounded-xl py-2.5 duration-500
                ${tab === activeTab ? 'bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985]' : ''}`}
            onClick={onClick}
        >
            <div className='relative w-[10%]'>
                <FontAwesomeIcon icon={icon} className='text-lg' />
            </div>
            <span className="w-[75%] md:w-[60%] text-start">{label}</span>
        </button>
    );

    const SubTabButton = ({ label, onClick }) => (
        <button
            className={`flex justify-center gap-3 duration-500 font-bold rounded-xl py-1 text-sm 
            ${label === activeSubTab ? 'text-pink-600' : ''}`}
            onClick={onClick}
        >
            <span className="w-[75%] md:w-[70%] text-start">{label}</span>
        </button>
    );

    const [welcomePopup, setWelcomePopup] = useState(false);
    const [activePopup, setActivePopup] = useState(1);

    useEffect(() => {
        sessionStorage.getItem('DemoPopup') === 'true' ? setWelcomePopup(false) : setWelcomePopup(true);
    }, []);

    const handleWelcome = (action) => {
        if (action === 'next' || action === 'continue') {
            if (activePopup < 3) {
                setActivePopup(activePopup + 1);
            } else {
                setWelcomePopup(false);
                sessionStorage.setItem('DemoPopup', 'true');
            }
        } else if (action === 'back') {
            if (activePopup > 1) {
                setActivePopup(activePopup - 1);
            }
        } else if (action === 'skip') {
            setWelcomePopup(false);
            sessionStorage.setItem('DemoPopup', 'true');
        }
    };

    return (
        <div className='text-white'>
            {/* <Toaster position='top-center' /> */}
            {welcomePopup && (
                <div
                    className="font-poppins fixed inset-0 z-30 flex justify-center items-center bg-cover bg-gradient-to-r from-[#E43875] to-[#F98736] lg:bg-Mbcbg"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                >
                    <div className="relative w-[90%] mx-auto flex justify-center items-center">
                        <div
                            className={`Popup-1 absolute w-[90%] md:w-[50%] lg:w-[30%] transition-transform duration-500 ${activePopup === 1
                                ? 'scale-100 z-10 translate-x-0'
                                : activePopup === 2
                                    ? '-translate-x-[100%] scale-90 opacity-50'
                                    : 'translate-x-[100%] scale-90 opacity-50'
                                }`}
                        >
                            <div className="border-2 border-white p-5 2xl:py-10 rounded-xl">
                                <div className="relative">
                                    <div className="bg-white/5 border border-white rounded-md flex py-5 px-3 mb-14">
                                        <div className="w-2/3">
                                            <h2 className="text-lg font-bold">We help you grow</h2>
                                            <div className="mt-2">
                                                <img src="/assets/Welcomegraph.png" alt="Graph" />
                                            </div>
                                        </div>
                                        <div className="w-1/3 font-bold text-center pt-1">
                                            <p>1 Year Profits </p>
                                            <p>$100k+</p>
                                        </div>
                                    </div>
                                    <img
                                        src="/assets/Icon (2).png"
                                        className="absolute bg-white rounded-full p-2 w-[72px] h-[72px] right-5 -bottom-12"
                                        alt="Icon"
                                    />
                                </div>
                                <h1 className="text-center font-bold text-xl 2xl:text-2xl">Welcome to your dashboard</h1>
                                <p className="text-center text-xs 2xl:text-sm mt-1 2xl:mt-3">
                                    We’re glad to have you onboard. Here are some quick tips to get you up and running.
                                </p>
                                <div className="flex justify-center gap-1 items-center my-5">
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 1
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 2
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 3
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                </div>
                                <div className="flex justify-center gap-5 mt-5">
                                    <button
                                        className="bg-white/15 font-bold w-1/2 py-1 border rounded-full"
                                        onClick={() => handleWelcome('skip')}
                                    >
                                        Skip
                                    </button>
                                    <button
                                        className="text-shadow-custom bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985] border border-white/10 font-bold w-1/2 py-1 rounded-full"
                                        onClick={() => handleWelcome('next')}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div
                            className={`Popup-2 absolute w-[90%] md:w-[50%] lg:w-[30%] transition-transform duration-500 ${activePopup === 2
                                ? 'scale-100 z-10 translate-x-0'
                                : activePopup === 1
                                    ? 'translate-x-[100%] scale-90 opacity-50'
                                    : '-translate-x-[100%] scale-90 opacity-50'
                                }`}
                        >
                            <div className="border-2 border-white p-7 2xl:py-10 rounded-xl">
                                <div className="relative">
                                    <div className="bg-white/5 border border-white rounded-md py-5 px-3 mb-14">
                                        <img src="/assets/Welcomegraph2.png" className="w-[80%] mx-auto" alt="Graph" />
                                    </div>
                                    <div className="absolute bg-white rounded-full p-2 w-[72px] h-[72px] right-5 -bottom-12 flex items-center justify-center">
                                        <i className="fa-solid fa-wallet text-4xl" />
                                    </div>
                                </div>
                                <h1 className="text-center font-bold text-xl 2xl:text-2xl">Connect Your Wallet</h1>
                                <p className="text-center text-xs 2xl:text-sm mt-1 2xl:mt-3">
                                    Hey there! Connect your wallet to explore and enjoy all our latest subscription packages.
                                </p>
                                <div className="flex justify-center gap-1 items-center my-5">
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 1
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 2
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 3
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                </div>
                                <div className="flex justify-center gap-5 mt-5">
                                    <button
                                        className="bg-white/15 font-bold w-1/2 py-1 border rounded-full"
                                        onClick={() => handleWelcome('back')}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="text-shadow-custom bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985] border border-white/10 font-bold w-1/2 py-1 rounded-full"
                                        onClick={() => handleWelcome('next')}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div
                            className={`Popup-3 absolute w-[90%] md:w-[50%] lg:w-[30%] transition-transform duration-500 ${activePopup === 3
                                ? 'scale-100 z-10 translate-x-0'
                                : activePopup === 2
                                    ? 'translate-x-[100%] scale-90 opacity-50'
                                    : '-translate-x-[100%] scale-90 opacity-50'
                                }`}
                        >
                            <div className="border-2 border-white py-7 2xl:py-10 rounded-xl">
                                <div className="relative">
                                    <div className="bg-white/5 border border-white rounded-md flex py-5 px-3 mb-14 mx-7">
                                        <img src="/assets/Welcomegraph3.png" alt="Graph" />
                                    </div>
                                    <div className="absolute bg-white rounded-full p-2 w-[72px] h-[72px] right-10 -bottom-12 flex items-center justify-center">
                                        <i className="fa-solid fa-dollar-sign text-4xl"></i>
                                    </div>
                                </div>
                                <h1 className="text-center font-bold text-xl 2xl:text-2xl">Subscription Required for Referrals</h1>
                                <p className="text-center text-xs 2xl:text-sm mt-1 2xl:mt-3 mx-7">
                                    Want to share the love? You can send referral links to your friends once you’ve subscribed!
                                </p>
                                <div className="flex justify-center gap-1 items-center my-5">
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 1
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 2
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                    <div
                                        className={`w-2.5 h-2.5 border rounded-md ${activePopup === 3
                                            ? 'bg-gradient-to-r from-[#E43875] to-[#F98736]'
                                            : 'bg-white'
                                            }`}
                                    ></div>
                                </div>
                                <div className="flex justify-center gap-5 mt-5 mx-7">
                                    <button
                                        className="bg-white/15 font-bold w-1/2 py-1 border rounded-full"
                                        onClick={() => handleWelcome('back')}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="text-shadow-custom bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985] border border-white/10 font-bold w-1/2 py-1 rounded-full"
                                        onClick={() => handleWelcome('continue')}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='fixed inset-0 z-0 bg-gradient-to-r from-[#E43875] to-[#F98736]'>
                <img src='/assets/NewMBCbg.png' className='hidden md:block h-full w-full object-cover object-top'></img>
            </div>
            <div className="relative z-10  font-poppins">
                <DashboardHeader selectedTab={selectedTab} />
                <div className='p-5 md:flex gap-5 mt-20 md:mt-[5%] lg:mt-[4%] 2xl:mt-[3%]'>
                    <div className="hidden md:block w-[25%] lg:w-[20%] fixed left-0">
                        <nav className='mt-[30%]'>
                            <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => handleTabClick('Dashboard')} />
                            {/* <TabButton label='Add Fund' tab='AddFund' icon={iconMap['wallet']} onClick={() => { handleTabClick('AddFund'); handleAddfund(); handleSubTabClick('Add Fund') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Addfund ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Add Fund' onClick={() => { handleSubTabClick('Add Fund') }} />
                                <SubTabButton label='Deposit History' onClick={() => handleSubTabClick('Deposit History')} />
                            </div>  */}
                            <TabButton label='Subscription' tab='Subscription' icon={iconMap['credit']} onClick={() => { handleTabClick('Subscription'); handleSubscribe(); handleSubTabClick('Subscribe') }} />
                            {addressStatus === true ?
                                <TabButton label='Whitelist' tab='Whitelist' icon={iconMap['credit']} onClick={() => { handleTabClick('Whitelist'); handleWhitelist(); handleSubTabClick('Whitelist') }} />
                                : ''}
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                    ${whitelist ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Whitelist' onClick={() => { handleSubTabClick('Whitelist') }} />
                                {address === AdminAddress ?
                                    <SubTabButton label='Add Whitelist' onClick={() => handleSubTabClick('Add Whitelist')} />
                                    : ''
                                }
                            </div>

                            {/* <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Subscribe ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Subscribe' onClick={() => { handleSubTabClick('Subscribe') }} />
                                <SubTabButton label='Subscribe History' onClick={() => handleSubTabClick('Subscribe History')} />
                            </div> */}
                            <TabButton label='My Directs' tab='My Directs' icon={iconMap['users']} onClick={() => { handleTabClick('My Directs'); handleTeam(); handleSubTabClick('All Team') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Team ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='All Team' onClick={() => { handleSubTabClick('All Team') }} />
                                <SubTabButton label='Direct Team' onClick={() => handleSubTabClick('Direct Team')} />
                                <SubTabButton label='InDirect Team' onClick={() => handleSubTabClick('InDirect Team')} />
                                <SubTabButton label='Visual Structure' onClick={() => handleSubTabClick('Visual Structure')} />
                            </div>
                            {/* <TabButton label='Reports' tab='Reports' icon={iconMap['file']} onClick={() => { handleTabClick('Reports'); handleReport(); handleSubTabClick('Direct Income') }} /> */}
                            {/* <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Report ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Income' onClick={() => { handleSubTabClick('Direct Income') }} />
                                <SubTabButton label='Level Income' onClick={() => handleSubTabClick('Level Income')} />
                            </div> */}
                            <TabButton label='Withdraw' tab='Withdraw' icon={iconMap['withdraw']} onClick={() => { handleTabClick('Withdraw'); handleWithdraw(); handleSubTabClick('Withdraw Income') }} />
                            {/* <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${withdraws ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Withdraw Income' onClick={() => { handleSubTabClick('Withdraw Income') }} />
                                <SubTabButton label='Withdraw Report' onClick={() => handleSubTabClick('Withdraw Report')} />
                            </div> */}
                            <TabButton label='Products' tab='Products' icon={iconMap['file']} onClick={() => handleTabClick('Products')} />
                            <TabButton label='Profile' tab='Profile' icon={iconMap['id']} onClick={() => handleTabClick('Profile')} />
                            <TabButton label='Support' tab='Support' icon={iconMap['Support']} onClick={() => { handleTabClick('Support'); handleSupport(); handleSubTabClick('Raise Ticket') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${support ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Raise Ticket' onClick={() => { handleSubTabClick('Raise Ticket') }} />
                                <SubTabButton label='User Tickets' onClick={() => handleSubTabClick('User Tickets')} />
                            </div>
                            <button
                                className={`flex justify-center gap-3 w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[75%] mx-auto font-bold rounded-xl py-2.5 duration-500`}
                                onClick={Logout}>
                                <div className='relative w-[10%]'>
                                    <FontAwesomeIcon icon={iconMap['logout']} className='text-lg' />
                                </div>
                                <span className="w-[75%] md:w-[60%] text-start">Logout</span>
                            </button>

                        </nav>
                    </div>

                    <div className='block md:hidden'>
                        <button onClick={handleOpenNavbar} ref={buttonRef}>
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                            </svg>
                        </button>
                        {isOpenNavbar && (
                            <nav className='w-[80%] z-10 py-5 absolute border rounded-3xl bg-[#872245] text-white grid content-around ' data-aos="fade-right" data-aos-duration="1000" ref={dropdownRef} >
                                <div>
                                    <div>
                                        <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => handleTabClick('Dashboard')} />
                                        {/* <TabButton label='Add Fund' tab='AddFund' icon={iconMap['wallet']} onClick={() => { handleTabClick('AddFund'); handleAddfund(); handleSubTabClick('AddFund') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Addfund ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Add Fund' onClick={() => { handleSubTabClick('AddFund') }} />
                                            <SubTabButton label='Deposit History' onClick={() => handleSubTabClick('Deposit')} />
                                        </div> */}
                                        <TabButton label='Subscription' tab='Subscription' icon={iconMap['credit']} onClick={() => { handleTabClick('Subscription'); handleSubscribe(); handleSubTabClick('Subscribe') }} />
                                        {addressStatus === true ?
                                            <TabButton label='Whitelist' tab='Whitelist' icon={iconMap['credit']} onClick={() => { handleTabClick('Whitelist'); handleWhitelist(); handleSubTabClick('Whitelist') }} />
                                            : ''}

                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${whitelist ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Whitelist' onClick={() => { handleSubTabClick('Whitelist') }} />
                                            {address === AdminAddress ?
                                                <SubTabButton label='Add Whitelist' onClick={() => handleSubTabClick('Add Whitelist')} />
                                                : ''
                                            }
                                        </div>
                                        {/* <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Subscribe ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Subscribe' onClick={() => { handleSubTabClick('Subscribe') }} />
                                            <SubTabButton label='Subscribe History' onClick={() => handleSubTabClick('SubscribeHistory')} />
                                        </div> */}
                                        <TabButton label='My Directs' tab='My Directs' icon={iconMap['users']} onClick={() => { handleTabClick('My Directs'); handleTeam(); handleSubTabClick('All Team') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Team ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='All Team' onClick={() => { handleSubTabClick('All Team') }} />
                                            <SubTabButton label='Direct Team' onClick={() => handleSubTabClick('Direct Team')} />
                                            <SubTabButton label='InDirect Team' onClick={() => handleSubTabClick('InDirect Team')} />

                                            {/* <SubTabButton label='Active Team' onClick={() => { handleSubTabClick('Active Team') }} />
                                            <SubTabButton label='Inactive Team' onClick={() => { handleSubTabClick('Inactive Team') }} /> */}
                                            <SubTabButton label='Visual Structure' onClick={() => handleSubTabClick('Visual Structure')} />
                                        </div>
                                        {/* <TabButton label='Reports' tab='Reports' icon={iconMap['file']} onClick={() => { handleTabClick('Reports'); handleReport(); handleSubTabClick('Direct Income') }} /> */}
                                        {/* <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Report ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Direct Income' onClick={() => { handleSubTabClick('Direct Income') }} />
                                            <SubTabButton label='Level Income' onClick={() => handleSubTabClick('Level Income')} />
                                        </div> */}
                                        <TabButton label='Withdraw' tab='Withdraw' icon={iconMap['withdraw']} onClick={() => { handleTabClick('Withdraw'); handleWithdraw(); handleSubTabClick('Withdraw Income') }} />
                                        {/* <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${withdraws ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Withdraw Income' onClick={() => { handleSubTabClick('Withdraw Income') }} />
                                            <SubTabButton label='Withdraw Report' onClick={() => handleSubTabClick('Withdraw Report')} />
                                        </div> */}
                                        <TabButton label='Products' tab='Products' icon={iconMap['file']} onClick={() => handleTabClick('Products')} />
                                        <TabButton label='Profile' tab='Profile' icon={iconMap['id']} onClick={() => handleTabClick('Profile')} />
                                        {/* <TabButton label='Support' tab='Support' icon={iconMap['id']} onClick={() => handleTabClick('Support')} /> */}
                                        <TabButton label='Support' tab='Support' icon={iconMap['Support']} onClick={() => { handleTabClick('Support'); handleSupport(); handleSubTabClick('Raise Ticket') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${support ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Raise Ticket' onClick={() => { handleSubTabClick('Raise Ticket') }} />
                                            <SubTabButton label='User Tickets' onClick={() => handleSubTabClick('User Tickets')} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center py-3 text-lg'><button className=' font-bold' onClick={Logout}>Logout</button></div>
                                </div>
                            </nav>
                        )}
                    </div>


                    <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        <Analysis />
                    </div>
                    <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Add Fund' ? <Addfunds /> : activeSubTab === 'Deposit History' ? <Deposit /> : null}
                    </div>
                    <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Subscribe' ? <Subscription /> : activeSubTab === 'Subscribe History' ? <SubscriptionHistory /> : null}
                    </div>
                    <div
                        id='div4'
                        style={{ display: active === 'div4' ? 'block' : 'none' }}
                        className='md:w-[75%] lg:w-[80%] md:absolute right-0'
                    >
                        {(() => {
                            switch (activeSubTab) {
                                case 'All Team':
                                    return <AllTeam />;
                                case 'Direct Team':
                                    return <DirectTeam />;
                                case 'InDirect Team':
                                    return <InDirectTeam />;
                                case 'Active Team':
                                    return <ActiveTeam />;
                                case 'Inactive Team':
                                    return <InactiveTeam />;
                                case 'Visual Structure':
                                    return <VisualStructure />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div5' style={{ display: active === 'div5' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Direct Income' ? <DirectIncome /> : activeSubTab === 'Level Income' ? <LevelIncome /> : null}
                    </div>
                    <div id='div6' style={{ display: active === 'div6' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Withdraw Income' ? <WithdrawIncome /> : activeSubTab === 'Withdraw Report' ? <WithdrawReport /> : null}
                    </div>
                    <div id='div7' style={{ display: active === 'div7' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        <Skilleareumai />
                    </div>
                    <div id='div8' style={{ display: active === 'div8' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        <AccountSettings />
                    </div>
                    <div id='div9' style={{ display: active === 'div9' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Raise Ticket' ? <Support /> : activeSubTab === 'User Tickets' ? <UserTickets /> : null}
                    </div>
                    <div id='div10' style={{ display: active === 'div10' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Whitelist' ? <Whitelist /> : activeSubTab === 'Add Whitelist' ? <AddWhiteList /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}