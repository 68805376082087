import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Tree from "react-d3-tree";
import axios from 'axios';
import { useSelector } from "react-redux";
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";

const test = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED"
  }
};

const getmembershipAmount = (planId) => {
  const membershipMap = {
    1: '$ 7',
    2: '$ 50',
    3: '$ 200',
    4: '$ 600',
    5: '$ 1500',
    6: '$ 3000',
    7: '$ 5000',
    8: '$ 10000'
  };
  return membershipMap[planId] || '$ 0';
};

const renderCustomNode = ({ nodeDatum, toggleNode }) => (
  <g style={{ cursor: 'pointer' }} onClick={() => toggleNode()}>
    <circle r={7} fill="#000000" />

    <foreignObject x="-100" y="-70" width="200" height="200"> {/* Adjusted x and y for centering */}
      <img
        src={nodeDatum.image || 'https://img.icons8.com/?size=100&id=aZ0WjSq1u1YB&format=png&color=000000'}
        width="40"
        height="40"
        style={{ marginBottom: '5px', borderRadius: '50%', display: 'block', margin: '0 auto' }}
        alt={nodeDatum.name}
      />
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '10px',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
          textAlign: 'center'  // Centering text
        }}
      >
        <p className=' text-black uppercase' style={{ fontSize: '14px', fontWeight: 'bold', margin: 0 }}>
          {nodeDatum.name}
        </p>
        <p className='text-black/70' style={{ fontSize: '12px' }}>
          Cus ID: {nodeDatum.attributes.CusID}
        </p>
        {/* <p className='text-black/70' style={{ fontSize: '12px' }}>
          Wallet Address: {nodeDatum.attributes.wallet}
        </p> */}
        <p className='text-black/70' style={{ fontSize: '12px' }}>
        Membership: {nodeDatum.attributes.planid !== null ? getmembershipAmount(nodeDatum.attributes.planid) : '$ 0'}
        </p>
        <p className='text-black/70' style={{ fontSize: '12px' }}>
          Club: {nodeDatum.attributes.clubid ?? '-'}
        </p>
        {nodeDatum.attributes.level > 0  ?
          <p className='text-black/70' style={{ fontSize: '12px' }}>
            Level : {nodeDatum.attributes.level ?? '-'}
          </p> : ""
        }
      </div>
    </foreignObject>
  </g>
);


function MLMTree() {

  const user = useSelector((state) => state.user);
  const email = user[0]?.email;
  const CusID = user[0]?.CusID;

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    axios.get(`${SERVER_URL}/api/getMLMtree?CusID=${CusID}`)
      .then((response) => {
        console.log("response", response);
        setTreeData([response.data]);
      })
      .catch((error) => {
        console.error('Error fetching tree data:', error);
      });
  }, []);

  const [inform, setInform] = useState(true)

  return (
    <div className="App relative">
      {inform &&
        <div className="Information-tip absolute bottom-20 md:bottom-5 2xl:bottom-10 left-1/2 -translate-x-1/2 text-black text-sm bg-white rounded-full w-[100%] md:w-[60%] lg:w-auto flex items-center gap-3 px-3 md:px-5 py-2 md:py-3 shadow-md">
          <div className="bg-white w-5 h-5 absolute -top-4 left-7 custom-clip shadow-md"></div>
          <img src='/assets/cookie.svg' className="w-7"></img>
          <p>Click on the user to view the extended tree.</p>
          <button className="bg-gradient-to-r from-[#E43875] to-[#F98736] px-5 py-1 rounded-full font-medium text-white" onClick={() => {setInform(false)}}>Okay</button>
        </div>
      }
      <div id="treeWrapper" className="flex items-center justify-center" style={{ width: "100%", height: "88vh" }}>
        {treeData.length > 0 ? (
          <Tree
            data={treeData}
            renderCustomNodeElement={renderCustomNode}
            nodeSvgShape={test}
            pathFunc="step"
            separation={{ siblings: 2, nonSiblings: 2 }}
            nodeSize={{x: 200, y: 200}}
            orientation="vertical"
            translate={{ x: 550, y: 250 }}
            allowForeignObjects={true}
            initialDepth={1}  // Try increasing the depth if nodes are collapsing too early
          />
        ) : (
          <p className="font-bold -translate-y-10">Loading...</p>
        )}
      </div>
    </div >
  );
}
export default MLMTree;