import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MBCPayOut_ContractAddress, MBCToken_ContractAddress, SERVER_URL } from '../../config';
import { ethers } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { token_abi } from '../../ABI';

export default function GotoDashboard() {
    const { walletProvider } = useWeb3ModalProvider();
    const [balance, setBalance] = useState(0);

    const [data, setData] = useState(null);
    const fetchBalance = async () => {
        try {
            const tokenAbi = [
                {
                    "constant": true,
                    "inputs": [{ "name": "_owner", "type": "address" }],
                    "name": "balanceOf",
                    "outputs": [{ "name": "balance", "type": "uint256" }],
                    "type": "function"
                }
            ];
            const provider = new ethers.providers.JsonRpcProvider("https://red-special-sea.bsc.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2");
            const contract = new ethers.Contract("0x55d398326f99059fF775485246999027B3197955", tokenAbi, provider);
            const balance = await contract.balanceOf(MBCPayOut_ContractAddress);
           const formattedBalance = ethers.utils.formatUnits(balance, 18);
           console.log(formattedBalance,'formatted balance')
           setBalance(formattedBalance > 0 ? parseFloat(formattedBalance).toFixed(2) : 0);
        } catch (error) {
            console.error("Error fetching balance:", error);
        }
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getDashboardDetails`);
            if (response.data.status === true) {
                setData(response.data.data);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error("Error fetching Dasboard data:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchBalance();
    }, []);

    return (
        <div
            data-aos="fadeIn"
            data-aos-duration="2000"
            className="flex flex-col justify-center items-center h-full text-white"
        >
            <div className="w-full max-w-4xl py-28 px-4">
                <div className="grid grid-cols- md:grid-cols-3 gap-5">
                <div className="flex flex-col justify-between p-5  border rounded-lg shadow-lg">
                        <h2 className="text-xs font-bold">
                            <FontAwesomeIcon icon={faUser} className="pr-2" />Total Income
                        </h2>
                        <p className="mt-2 text-2xl font-bold ">{balance} $</p>
                        <p className="text-gray-400">Total Subscription Income</p>
                    </div>
                    <div className="flex flex-col justify-between p-5  border rounded-lg shadow-lg">
                        <h2 className="text-xs font-bold">
                            <FontAwesomeIcon icon={faUser} className="pr-2" /> Total Members
                        </h2>
                        <p className="mt-2 text-4xl font-bold ">{data ? data.totalUsers : 0}</p>
                        <p className="text-gray-400">Registered Members</p>
                    </div>

                    <div className="flex flex-col justify-between p-5  border rounded-lg shadow-lg">
                        <h2 className="text-xs font-bold">
                            <FontAwesomeIcon icon={faUser} className="pr-2" /> Active Members
                        </h2>
                        <p className="mt-2 text-4xl font-bold ">{data ? data.activeUsers : 0}</p>
                        <p className="text-gray-400">Active Users</p>
                    </div>
                   
                    
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
                <div className="flex flex-col justify-between p-5  border rounded-lg shadow-lg">
                        <h2 className="text-xs font-bold">
                            <FontAwesomeIcon icon={faUser} className="pr-2" /> Deactive Members
                        </h2>
                        <p className="mt-2 text-4xl font-bold ">{data ? data.inactiveUsers : 0}</p>
                        <p className="text-gray-400">Inactive Users</p>
                    </div>
                    <div className="flex flex-col justify-between p-5  border rounded-lg shadow-lg">
                        <h2 className="text-xs font-bold">
                            <FontAwesomeIcon icon={faUser} className="pr-2" /> Upgraded Members
                        </h2>
                        <p className="mt-2 text-4xl font-bold ">{data ? data.subscribedUsers : 0}</p>
                        <p className="text-gray-400">Topup Members</p>
                    </div>

                    <div className="flex flex-col justify-between p-5  border rounded-lg shadow-lg">
                        <h2 className="text-xs font-bold">
                            <FontAwesomeIcon icon={faUser} className="pr-2" /> Not Upgraded Members
                        </h2>
                        <p className="mt-2 text-4xl font-bold ">{data ? data.unsubscribedUsers : 0}</p>
                        <p className="text-gray-400">Un Topup Members</p>
                    </div>
                </div>
            </div>
        </div>
    );
}