import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShareNodes } from "@fortawesome/free-solid-svg-icons";

const ReferralLink = () => {

  const linktocopy = "https://www.skilleareum.ai";
  const handleLinkCopy = () => {
    navigator.clipboard.writeText(linktocopy)
      .then(() => {
        console.log("Referral link copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };

  const codetocopy = "MBC-100-01932342352423";
  const handlecodecopy = () => {
    navigator.clipboard.writeText(codetocopy)
      .then(() => {
        console.log("Referral code copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };


  return (
    <div>
      <div className='fixed inset-0 z-0 bg-gradient-to-r from-[#E43875] to-[#F98736]'>
        <img src='/assets/NewMBCbg.png' className='hidden md:block h-full w-full object-cover object-top'></img>
      </div>
      <div className="relative z-10 text-white">
        <Header />
        <div className="xl:w-[75%] w-[90%] pt-24 pb-10 mx-auto flex justify-center items-center min-h-screen">
          <div className="flex md:flex-row flex-col justify-center gap-10 border-2 rounded-lg lg:p-10 sm:p-5 p-3 w-full">
            <div className="lg:w-[45%] sm:w-[40%]">
              <h1 className="mb-2 text-2xl lg:text-5xl font-bold capitalize text-shadow-custom">Refer friends & start earning together. </h1>
              <div className="">
                <p className="font-semibold ">Invite your friends and earn from their referral too!</p>
                <div className="w-32 h-0.5 bg-white mt-1"></div>
              </div>
            </div>

            <div className="lg:w-[55%] sm:w-[60%]">
              <div className="bg-white rounded-lg py-5 md:px-8 px-3">
                <div className="">
                  <p className="text-black text-lg font-semibold">Referral Link & code</p>
                </div>
                <div>
                  <div className="bg-[#FFD8E6A6] rounded-xl mt-5">
                    <div className="flex sm:flex-row flex-col justify-center items-center gap-5 p-3">
                      <p className="text-[#F23985] font-semibold">Friend receive  15%</p>
                      <div className="sm:w-0.5 w-[80%] sm:h-10 h-0.5 bg-[#F23985] rounded-lg"></div>
                      <p className="text-[#F23985] font-semibold">You receive  30%</p>
                    </div>
                  </div>

                  <div className="mt-5 w-[95%] mx-auto ">
                    <p className="text-black/45 font-semibold">Referral code</p>
                    <div className="bg-[#EFEFEF] rounded-xl px-5 py-3 flex justify-between items-center gap-2">
                      <div className=" cursor-pointer pr-3 overflow-hidden overflow-x-auto ">
                        <button onClick={handlecodecopy}>
                          <p className="text-[#F23985] text-sm font-semibold " >{codetocopy}</p>
                        </button>
                      </div>
                      <div>
                        <button onClick={handlecodecopy}>
                          <FontAwesomeIcon icon={faCopy} className="text-[#F23985] font-semibold" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 w-[95%] mx-auto ">
                    <p className="text-black/45 font-semibold">Referral link</p>
                    <div className="bg-[#EFEFEF] rounded-xl px-5 py-3 flex justify-between items-center gap-2">
                      <div className="w-full cursor-pointer pr-3 overflow-hidden overflow-x-auto " >
                        <button onClick={handleLinkCopy}>
                          <p className="text-[#F23985] text-sm font-semibold " >{linktocopy}</p>
                        </button>
                      </div>
                      <div>
                        <button onClick={handleLinkCopy}>
                          <FontAwesomeIcon icon={faCopy} className="text-[#F23985] font-semibold" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="w-[95%] cursor-pointer mx-auto rounded-xl flex justify-center items-center gap-2 py-3 shadow-lg shadow-black/30 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-semibold">
                      <p className="font-semibold">Share Referral</p>
                      <FontAwesomeIcon icon={faShareNodes} className="text-white" />
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
        <Footer />
      </div>
    </div>


  )
}

export default ReferralLink