import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEye, faEyeSlash, faL } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { SERVER_URL } from "../../config";
import { setAdminEmail, SET_TOKEN } from "../../store";
import { useSelector } from "react-redux";

AOS.init();

const AdminLogin = () => {

    const admin_email = useSelector((state) => state.admin_email);
    console.log("admin_email", admin_email);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [visible, setVisible] = useState(false);

    const handlePasswordVisibility = () => {
        setVisible(!visible);
    };

    const Login = async () => {
        try {
            if (email === "") {
                toast.error("Please enter the Email");
                return false;
            }
            if (password === "") {
                toast.error("Please enter the Password");
                return false;
            }
            const response = await axios.get(`${SERVER_URL}/api/adminlogin?email=${email}&password=${password}`);

            if (response.data.status === 'success') {
                toast.success('Welcome to MBC Admin Panel', {
                    autoClose: 1000,
                });
                dispatch(setAdminEmail(email));
                setTimeout(() => {
                    navigate('/Admin/dashboard');
                }, 2000);
            } else {
                if (response.data.msg === 'Invalid Credentials') {
                    toast.error('Invalid Credentials.');
                }
            }
        } catch (error) {
            console.error('Error during login:', error);
            toast.error('An error occurred. Please try again later.');
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }
    };

    return (
        <div>
            <div className='fixed inset-0 z-0 bg-gradient-to-r from-[#E43875] to-[#F98736]'>
                <img src='/assets/NewMBCbg.png' className='hidden md:block h-full w-full object-cover object-top'></img>
            </div>
            <div className='relative z-10 font-poppins text-white flex items-center min-h-screen'>
                <div className="w-[90%] m-auto flex flex-col lg:flex-row gap-5 md:gap-0  justify-around items-center py-5">
                    <div className="lg:w-[45%] w-full h-full"
                        data-aos="fade-right"
                        data-aos-duration="2000">
                        <div>
                            <p className="font-bold text-lg text-center md:text-5xl mb-5  text-shadow-custom">Admin Panel</p>
                        </div>
                        <div><img className="w-[60%] mx-auto rounded-md" src="https://img.freepik.com/premium-vector/people-working-concept-illustration_958800-130918.jpg?w=740" ></img></div>
                    </div>

                    <div className="lg:w-[45%] 2xl:w-[35%]" data-aos="fade-left" data-aos-duration="2000" u>
                        <div className="lg:w-[90%] border-2 rounded-md py-5 md:px-10 px-3">
                            <div className="text-center">
                                <p className=" text-3xl font-bold">SIGN IN</p>
                                <p className=" mt-1 text-sm px-2">
                                    Access to our Admin dashboard.
                                </p>
                            </div>
                            <div className=" py-3 pb-7 text-[#F23985] font-medium">
                                <div className="mt-5 relative">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10  text-sm `}
                                        placeholder="Enter Email"
                                    />
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-6 relative">
                                    <input
                                        type={visible ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={`w-full rounded-lg focus:outline-none py-3 px-10  text-sm `}
                                        placeholder="Enter the password"
                                    />
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                    <button
                                        onClick={handlePasswordVisibility}
                                        className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[40px]"
                                    >
                                        {visible ? (
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                size="sm"
                                                className="text-gray-400"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                size="sm"
                                                className="text-gray-400"
                                            />
                                        )}
                                    </button>
                                </div>
                                <div className="mt-8 cursor-pointer" >
                                    <div className="shadow-lg text-white shadow-black/30 bg-white py-1 px-2 rounded-xl">
                                        <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center"
                                            onClick={Login}
                                        >
                                            Sign up
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="mt-5">
                                <p className="font-semibold text-sm">Or continue with</p>
                                <div className="flex justify-between gap-2 mt-2 mb-5">
                                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg">
                                        <button
                                            className="flex items-center gap-2"
                                        >
                                            <img src="/assets/google.png" className="w-4 h-4" alt="Google Icon" />
                                            <p className="font-semibold text-black text-sm">Google</p>
                                        </button>
                                    </div>
                                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer" >
                                        <img src="/assets/fb.png" className="w-5" />
                                        <p className="font-semibold text-black text-sm">Facebook</p>
                                    </div>
                                </div>
                                <p className="text-xs text-center font-semibold">
                                    {" "}
                                    By registering you with our
                                    <span className="text-black font-semibold">
                                        {" "}
                                        Terms and Conditions.
                                    </span>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;

