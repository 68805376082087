import React, { useState, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import { SERVER_URL, PROVIDER_URL } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ethers } from 'ethers';
import { MBCPayOut_ContractAddress, MBCToken_ContractAddress , } from '../../../config'; 
import {  MBCToken_abi , MBCPayOut_abi} from '../../../ABI';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useWeb3ModalProvider } from '@web3modal/ethers5/react';
const WithdrawFunds = () => {
    const [loading, setLoading] = useState(false);
    const [totalBalance, setTotalBalance] = useState(0);
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    useEffect(() => {
        const fetchTotalBalance = async () => {
            try {
                const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
                
                const TokenContract = new ethers.Contract(MBCToken_ContractAddress, MBCToken_abi, provider);
                const balance = await TokenContract.balanceOf(MBCPayOut_ContractAddress);
                console.log("fetched Balance:", balance);
                setTotalBalance(ethers.utils.formatUnits(balance, 18));
            } catch (error) {
                console.error('Error fetching total balance:', error);
            }
        };
        
        fetchTotalBalance();
    }, [walletProvider, isConnected]);

    const withdrawRewards = async () => {
        if (!isConnected) {
            toast.error("Please Connect Wallet First");
            return false;
        }
        const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const MBCcontract = new ethers.Contract(MBCPayOut_ContractAddress,MBCPayOut_abi, signer);
        let admin = await MBCcontract.ADMIN();
        if (address !== admin) {
            toast.error("Connect admin wallet");
            return false;
        }

        const toastId = toast.loading("Processing withdrawal...");

        try {
            const tx = await MBCcontract.withdrawFunds({
                gasPrice: ethers.utils.parseUnits("5", "gwei"),
                gasLimit: 300000
            });
            await tx.wait();
            toast.dismiss(toastId);
            toast.success('Withdrawal Successful!');
        } catch (error) {
            console.error("Error withdrawing rewards:", error);
            toast.dismiss(toastId);
            toast.error("Withdrawal Failed!");
        } finally {
            setLoading(false);
        }
    };

    const handleWithdraw = async () => {

        setLoading(true);
        await withdrawRewards();
        setLoading(false); 
    };

    return (
        <div className="flex justify-center items-start h-screen mt-20">
            <div className="p-5 w-80">
                <h2 className="text-2xl font-bold mb-4 text-center">Withdraw Funds</h2>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-white">Total Balance: {totalBalance}</label>
                </div>
                {/* <div className="mb-4 ">
                    <label className="block text-sm font-medium text-white">Amount</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                    />
                </div> */}
                <div className="mb-4 flex justify-center"  >
                <button
                    onClick={handleWithdraw}
                    className="px-4 py-2 font-bold items-center rounded-md bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30"
                    disabled={loading}
                >
                    {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    ) : (
                        "Withdraw"
                    )}
                </button>
                </div>
              
            </div>
        </div>
    );
};

export default WithdrawFunds;